<template>
   <v-container>
        <v-row>
            <v-col cols="12" class="text-center">
                <p>Desenvolvido pela <a href="http://wa.me/5511978325116" target="_blank">Beeasy</a></p>
            </v-col>
        </v-row>
   </v-container>
</template>

<script>
export default {
    name: 'RodapePage',
    data() {
        return {
            logo: require('@/assets/logo.png')
        }
    }
}
</script>

<style scoped>
    p{
        margin-top: 20px;
        font-size: 14px;
        color: #fff;
        font-weight: 300
    }

    a{
        color: #136ee4;
        font-weight: bold;
        text-decoration: none;
    }
</style>